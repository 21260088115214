import { SUPPORT_EMAIL } from './common';

export const LOW_RISK_VALUE = 50;

export const getPopupText = (status: number, risk?: number, description?: string[]) => {
  if (status >= 400) {
    return `The purchase can't be processed due to issue on our side.\n Please, contact the support: ${SUPPORT_EMAIL}`;
  }
  if (status === 200) {
    const formattedDescription = description?.map((desc) => `- ${desc}`).join('\n');
    return `
            Hold on, it seems your wallet address is blacklisted!.\n Wallet risk level: ${risk}% \n 
            Caused activities:
            ${formattedDescription}
        `;
  }
  return '';
};
